import React from "react"
import { AppContext, AppProvider } from "./src/contexts/AppProvider"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "./src/styles/globals"
import { theme } from "./src/styles/theme"

export const wrapPageElement = ({ element }) => (
  <AppProvider>
    <AppContext.Consumer>
      {context => {
        return (
          <ThemeProvider theme={theme(context.darkMode)}>
            <GlobalStyle />
            {element}
          </ThemeProvider>
        )
      }}
    </AppContext.Consumer>
  </AppProvider>
)
