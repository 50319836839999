export const color = {
  primary: "#0B71B9",
  primaryLight: "#4992F3",
  primaryDark: "#3249A0",
  primaryTransparent: "#0C7ECF33",
  black: "#000000",
  darkGray: "#121212",
  white: "#FFFFFF",
  grayLight1: "#ECEFF0",
  grayLight2: "#DCE1E2",
  grayLight3: "#939DA0",
  grayLight4: "#6B7375",
  grayLight5: "#485558",
  grayDark1: "#ACACAC",
  grayDark2: "#818181",
  grayDark3: "#6D6D6D",
  grayDark4: "#4D4D4D",
  grayDark5: "#2B2B2B",
  error: "#b01720",
  errorLight: "#cf667a",
  errorDark: "#790c00",
}

export const font = {
  family: {
    paragraph: `"Roboto", sans-serif`,
    headings: `"Open Sans", sans-serif`,
  },
  lineHeight: {
    sm: "30px",
    md: "40px",
  },
  weight: {
    regular: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
  },
  size: {
    xxs: "0.75rem", //12px
    xs: "1rem", //16px
    sm: "1.125rem", //18px
    md: "1.25rem", //20px
    lg: "1.5rem", //24px
    xl: "2rem", //32px
    xxl: "3rem", //48px
  },
}

export const breakpoint = {
  xs: "576px",
  sm: "768px",
  md: "960px",
  lg: "1440px",
  xl: "1920px",
  xxl: "2560px",
}

export const spacing = {
  xxs: "8px",
  xs: "16px",
  sm: "24px",
  md: "32px",
  lg: "48px",
  xl: "56px",
  xxl: "104px",
  xxxl: "154px",
}

export const transition = {
  slow: "all 0.3s ease-out, all 0.25s ease-out",
  medium: "all 0.25s ease-out, all 0.2s ease-out",
  fast: "all 0.1s ease-out",
}

export const boxShadow = "0px 8px 32px 0px #1212121a"

export const borderRadius = {
  sm: "4px",
  md: "8px",
  lg: "16px",
}

export const elementSize = {
  contentWidth: {
    page: "1140px",
    text: "700px",
  },
  headerHeight: {
    mobile: "60px",
    desktop: "83px",
  },
  buttonSize: {
    sm: "30px",
    md: "40px",
    lg: "60px",
  },
  cardSize: {
    sm: "200px",
    md: "267px",
  },
}
