import { createGlobalStyle } from "styled-components"
import { borderRadius, color, font, transition } from "./variables"

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayLightscale;
    margin: 0;
    overflow-x: hidden;
    font-family: ${font.family.paragraph};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.regular};
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.background};
    transition: ${transition.medium};
  }

  p {
    font-size: ${font.size.xs};
    line-height: ${font.lineHeight.sm};
  }

  a {
    color: ${props => props.theme.accent};
    text-decoration: none;
    transition: ${transition.fast};

    &:hover {
      color: ${props => props.theme.accentHover};
    }
  }

  p a {
    color: ${props => props.theme.accent};
  }

  ul {
    line-height: ${font.lineHeight.sm};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-family: ${font.family.headings};
    font-weight: ${font.weight.bold};
  }

  h1 {
    font-size: ${font.size.xxl};
  }
  h2 {
    font-size: ${font.size.xl};
  }
  h3,
  h4 {
    font-size: ${font.size.md};
  }

  h5,
  h6 {
    font-size: ${font.size.sm};
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  svg path {
    &.colored {
      fill: ${props => props.theme.accent};
    }
    &.colored-dark {
      fill: ${props => props.theme.accentActive};
    }
    &.black {
      fill: ${props => props.theme.text};
    }
  }

  button {
    display: initial;
    padding: unset;
    font-family: ${font.family.paragraph};
    color: ${props => props.theme.text};
    text-align: unset;
    cursor: pointer;
    background-color: transparent;
    border-style: none;
    transition: ${transition.fast};
  }

  *:focus {
    outline: ${props => props.theme.accent};
  }

  button:focus-visible,
  a:focus-visible,
  input:focus-visible,
  select:focus-visible,
  textarea:focus-visible {
    border-radius: ${borderRadius.sm};
    outline: none;
    box-shadow: 0 0 0 0.08em ${color.primaryLight};
  }

  ::selection {
    background: ${color.primaryTransparent};
  }

  ::-moz-selection {
    background: ${color.primaryTransparent};
  }
`

export default GlobalStyle
