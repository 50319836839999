import { useLocalStorage } from "./useLocalStorage"
import { useMediaQuery } from "./useMediaQuery"

export function useDarkMode() {
  const [theme, setTheme] = useLocalStorage("dark-mode-enabled")
  const prefersDarkMode = usePrefersDarkMode()
  const selectedTheme = typeof theme !== "undefined" ? theme : prefersDarkMode

  return [selectedTheme, setTheme]
}

function usePrefersDarkMode() {
  return useMediaQuery(["(prefers-color-scheme: dark)"], [true], false)
}
