import { color } from "./variables"

export const theme = darkMode => {
  const text = !darkMode ? color.black : color.white
  const textInverted = !darkMode ? color.white : color.darkGray
  const textLight = !darkMode ? color.gray5 : color.gray2
  const background = !darkMode ? color.white : color.darkGray
  const backgroundLight = !darkMode ? color.white : color.grayDark5
  const backgroundLightHover = !darkMode ? color.grayLight1 : color.grayDark4
  const gray1 = !darkMode ? color.grayLight1 : color.grayDark5
  const gray2 = !darkMode ? color.grayLight2 : color.grayDark4
  const gray3 = !darkMode ? color.grayLight3 : color.grayDark3
  const gray4 = !darkMode ? color.grayLight4 : color.grayDark2
  const gray5 = !darkMode ? color.grayLight5 : color.grayDark1
  const accent = !darkMode ? color.primary : color.primaryLight
  const accentHover = !darkMode ? color.primaryLight : color.primary
  const accentActive = !darkMode ? color.primaryDark : color.primaryDark
  const error = !darkMode ? color.error : color.errorLight

  return {
    text: text,
    textInverted: textInverted,
    textLight: textLight,
    background: background,
    backgroundLight: backgroundLight,
    backgroundLightHover: backgroundLightHover,
    gray1: gray1,
    gray2: gray2,
    gray3: gray3,
    gray4: gray4,
    gray5: gray5,
    accent: accent,
    accentHover: accentHover,
    accentActive: accentActive,
    error: error,
  }
}
