import React, { createContext } from "react"
import { useDarkMode } from "../../hooks/useDarkMode"

export const AppContext = createContext({})

export const AppProvider = props => {
  const { children } = props
  const [darkMode, setDarkMode] = useDarkMode()

  return (
    <AppContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </AppContext.Provider>
  )
}
